:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.home-page__hero {
  text-align: center;
  padding: 100rem 0; }
  .home-page__hero-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding: 1rem 0 5rem 0; }
    @media (min-width: 540px) {
      .home-page__hero-grid {
        grid-template-columns: 4fr 2.5fr 1.5fr;
        padding: 10rem 0 5rem 0; } }
  .home-page__hero-header {
    align-self: center; }
  .home-page__hero-images {
    height: 40vh;
    background-image: url("../../../images/catheader.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 999px; }
    .home-page__hero-images:last-of-type {
      display: none;
      height: 20vh;
      background-image: url("../../../images/dogheader.jpeg"); }
      @media (min-width: 540px) {
        .home-page__hero-images:last-of-type {
          display: block; } }
  .home-page__hero-text {
    overflow: hidden; }
  @media (max-width: 540px) {
    .home-page__hero {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 56px);
      justify-content: space-between;
      padding: 1rem; }
      .home-page__hero-text {
        margin-top: 5rem;
        width: 100%; }
      .home-page__hero-action {
        margin-bottom: 3rem;
        width: 100%; } }
  .home-page__hero__title {
    color: #7d7d7d;
    display: inline-block;
    padding: 1rem 0;
    margin-bottom: 0.5rem; }
    @media (max-width: 540px) {
      .home-page__hero__title {
        padding: 0.6rem 1rem;
        width: calc(100% - 4rem); } }
    .home-page__hero__title h1 {
      display: inline-block;
      font-weight: 600;
      color: #160057;
      font-size: 4rem;
      font-weight: 900; }
      @media (max-width: 540px) {
        .home-page__hero__title h1 {
          font-size: 1.125rem; } }
      @media (min-width: 540px) and (max-width: 992px) {
        .home-page__hero__title h1 {
          font-size: 48px; } }

.home-page__categories {
  margin-bottom: 2rem; }
  .home-page__categories-wrapper {
    background-color: transparent;
    padding: 0; }
    @media (min-width: 540px) {
      .home-page__categories-wrapper {
        background-color: #FDF7EF;
        padding: 5rem;
        border-radius: 100px; } }
  .home-page__categories h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center; }
  .home-page__categories__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem; }
    @media (min-width: 540px) {
      .home-page__categories__list {
        grid-template-columns: 1fr 1fr 1fr; }
        .home-page__categories__list > div:last-child {
          grid-column: 4 / 1; } }
    .home-page__categories__list a {
      text-decoration: none;
      color: #160057; }
      .home-page__categories__list a h3 {
        text-transform: uppercase;
        font-weight: 600;
        text-align: center; }
        .home-page__categories__list a h3:after {
          content: url("../../../images/carousel-arrow.svg");
          display: inline-block;
          transform: rotate(180deg);
          margin-left: 1rem; }
    .home-page__categories__list__image {
      width: 100%;
      height: 30rem;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem;
      border-radius: 999px;
      border: 4px solid #fff; }
      .home-page__categories__list__image--no-photo {
        background-color: #C3EBED;
        background-repeat: no-repeat;
        background-size: contain; }
